import React from 'react';
import { ThemeProvider } from 'styled-components';

import { LayoutProps } from '../interfaces';
import { GlobalStyles } from '../styles/GlobalStyles';
import { theme } from '../utils/Theme';

export const MainPageLayout = ({ children }: LayoutProps) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <div
          style={{
            margin: '0 auto',
            maxWidth: 'auto',
            paddingTop: 0,
          }}
        >
          <main>{children}</main>
        </div>
      </>
    </ThemeProvider>
  );
};
