import { colors } from './Colors';
import { mediaQuerySizes } from './MediaQuerySizes';

export const theme = {
  colors,
  mediaQuerySizes,
  font: {
    thin: 300,
    regular: 400,
    bold: 800,
  },
};
