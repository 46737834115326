export const mediaQuerySizes = {
  bigScreen: '@media (max-width: 1600px)',
  bigLaptop: '@media (max-width: 1500px)',
  laptop: '@media (max-width: 1400px)',
  smallLaptop: '@media (max-width: 1200px)',
  tablet: '@media (max-width: 990px)',
  phone: '@media (max-width: 800px)',
  phoneLight: '@media (max-width: 650px)',
  smallScreen: '@media (max-width: 500px)',
};
