import { graphql, useStaticQuery } from 'gatsby';

import { queryResponse } from '../interfaces/AllKindergartensQueryResponse';
import { findKindergartenByAllKindergertensJson } from './FindKindergartenByAllKindergartensJson';

interface Kindergarten {
  name: string;
}

const query = graphql`
  query {
    allKindergartensJson {
      nodes {
        name
        facebook
        instagram
        youtube
      }
    }
  }
`;

export const findSocialUrl = (kindergartenName: string, social: 'facebook' | 'instagram' | 'youtube'): string => {
  const data: queryResponse<Kindergarten> = useStaticQuery(query);

  const edge = findKindergartenByAllKindergertensJson(data, kindergartenName);
  return edge ? edge[social] || '' : '';
};
