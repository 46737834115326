import React from 'react';
import styled from 'styled-components';

import SvgLogo from '../../../static/uploads/logo.svg';
import { MenuProps } from '../../interfaces';
import { theme } from '../../utils/Theme';

const StyledWrapper = styled.div<MenuProps>`
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: ${theme.colors.white};
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 998;
  padding-top: 50px;
  transform: translateX(${({ isOpen }: MenuProps) => (isOpen ? '0' : '-100%')});
  transition: all 0.25s ease-in-out;
  background-image: linear-gradient(225deg, ${theme.colors.selectionGreen}, ${theme.colors.darkGreen});
  text-align: center;

  a {
    background: linear-gradient(120deg, transparent 0%, transparent 50%, ${theme.colors.white} 50%);
    padding: 0.7rem 1.5rem;
    background-size: 215%;
    transition: all 0.4s;
    font-weight: 300;
    :hover,
    :active {
      margin-bottom: 0;
      background-position: 100%;
      transform: translateX(-0.5rem);
      color: ${theme.colors.darkGreen};
      text-shadow: none;
    }
    :last-child {
      margin-bottom: 50px;
    }
  }
  ${theme.mediaQuerySizes.smallLaptop} {
    display: grid;
  }
`;

const Logo = styled(SvgLogo)`
  position: absolute;
  z-index: 998;
  left: 0;
  top: 0;
  opacity: 0.2;
  width: 1000px;
  height: 1000px;
  transform: rotate(57deg);

  path,
  circle,
  ellipse {
    fill: ${theme.colors.darkGreen};
  }
`;

const LogoWrapper = styled.div`
  overflow: hidden;
  width: 100vw;
  height: 100%;
  position: inherit;
`;

export const MobileMenu = ({ isOpen, children }: MenuProps) => {
  return (
    <StyledWrapper isOpen={isOpen}>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      {children}
    </StyledWrapper>
  );
};
