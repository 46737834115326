import './layout.css';

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import Navbar from '../components/Navbar';
import { LayoutPropsWithLocation } from '../interfaces';
import { GlobalStyles } from '../styles/GlobalStyles';
import { theme } from '../utils/Theme';

const Footer = styled.footer`
  width: 100%;
  text-align: center;
  font-size: 0.9rem;
  padding-bottom: 10px;
  span {
    color: red;
  }
  a {
    color: ${theme.colors.dark};
    transition: all 0.4s ease-out;

    :hover {
      color: ${theme.colors.gray};
    }
  }
`;

export const Layout = ({ children, location }: LayoutPropsWithLocation) => {
  // fix for navbar https://github.com/gatsbyjs/gatsby/issues/11738#issuecomment-488660043
  if (location.pathname === '/offline-plugin-app-shell-fallback') return null;
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <Navbar />
        <div
          style={{
            margin: '0 auto',
            paddingTop: 0,
          }}
        >
          <main>{children}</main>
          <Footer>
            © {new Date().getFullYear()} &#8226; Made with <span>&hearts;</span>{' '}
            <a href="http://ideasfactory.pl">IdeasFactory</a> & <a href="https://www.webmentors.pl/">WebMentors</a>
          </Footer>
        </div>
      </>
    </ThemeProvider>
  );
};
