export function getKindergartenByPathname(pathname: string): string {
  if (pathname.includes('nowa-iwiczna')) {
    return 'nowa-iwiczna';
  } else if (pathname.includes('stara-iwiczna')) {
    return 'stara-iwiczna';
  } else if (pathname.includes('piaseczno')) {
    return 'piaseczno';
  } else {
    return '';
  }
}
