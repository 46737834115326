import React from 'react';
import styled from 'styled-components';

import { MenuProps } from '../../interfaces';
import { theme } from '../../utils/Theme';

const StyledHamburger = styled.button`
  padding: 22px 15px 22px 15px;
  border: none;
  background: none;
  position: fixed;
  right: 10px;
  z-index: 999;
  top: 12px;
  outline: none;
  display: none;
  transition: filter 0.2s ease-out;

  :hover {
    filter: brightness(110%);
  }

  ${theme.mediaQuerySizes.smallLaptop} {
    display: block;
    background-color: ${theme.colors.white};
    width: 58px;
    height: 58px;
    margin-right: 10px;
    border-radius: 50%;
  }
`;

const InnerHamburger = styled.div<MenuProps>`
  position: relative;
  width: 30px;
  height: 4px;
  border-radius: 9px;
  background-color: ${({ isOpen }) => (isOpen ? 'transparent' : theme.colors.pink)};
  transition: background-color 0.25s ease-in;
  ::after,
  ::before {
    content: '';
    position: absolute;
    left: 0;
    width: 30px;
    height: 4px;
    border-radius: 9px;
    transition: transform 0.25s ease-in-out;
  }

  ::before {
    top: -9px;
    background-color: ${({ isOpen }) => (isOpen ? theme.colors.green : theme.colors.blue)};
    transform: translateY(${({ isOpen }: MenuProps) => (isOpen ? '9px' : 0)})
      rotate(${({ isOpen }) => (isOpen ? '45deg' : '0')});
  }

  ::after {
    top: 9px;
    background-color: ${({ isOpen }) => (isOpen ? theme.colors.green : theme.colors.lightGreen)};
    transform: translateY(${({ isOpen }: MenuProps) => (isOpen ? '-9px' : 0)})
      rotate(${({ isOpen }) => (isOpen ? '-45deg' : '0')});
  }
`;

export const Hamburger = ({ isOpen, ...props }: MenuProps) => {
  return (
    <StyledHamburger aria-label="Menu" {...props}>
      <InnerHamburger isOpen={isOpen} />
    </StyledHamburger>
  );
};
