import React from 'react';
import { Location } from '@reach/router';

function withLocation(ComponentToWrap) {
  return function WrappedWithLocation(props) {
    return <Location>{({ location }) => <ComponentToWrap {...props} location={location} />}</Location>;
  };
}

export default withLocation;
