import { createGlobalStyle } from 'styled-components';

import { colors } from '../utils/Colors';

export const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Recoleta-Regular';
  }

  h1{
    font-family: 'Recoleta-SemiBold';
  }

  ::selection {
    background: ${colors.selectionGreen};
  }

  a {
    color: ${colors.logoGreen}
  }

  table {
    max-width: 100%;
    overflow-x: auto;
    display: block;
  }
`;
