import { queryResponse } from '../interfaces/AllKindergartensQueryResponse';

export interface Kindergarten {
  name: string;
  facebook?: string;
  instagram?: string;
  youtube?: string;
  email?: string;
  address?: string;
  firstPhone?: string;
  secondPhone?: string;
  geographicalCoordinates?: {
    latitude?: number;
    longitude?: number;
  };
}

export function findKindergartenByAllKindergertensJson(
  query: queryResponse<Kindergarten>,
  kindergartenName: string,
): Kindergarten | undefined {
  return query.allKindergartensJson.nodes.find((edge) => edge.name == kindergartenName);
}
