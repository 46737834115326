import { GatsbyLinkProps, graphql, Link, StaticQuery } from 'gatsby';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Logo from '../images/full-logo.svg';
import FacebookSvgIcon from '../images/icons/fb-circle-logo.svg';
import InstagramSvgIcon from '../images/icons/ig-circle.svg';
import YoutubeSvgIcon from '../images/icons/youtube.svg';
import { LocationProps, SubpagesNode } from '../interfaces';
import { decodeUri } from '../utils/DecodeUri';
import { getKindergartenByPathname } from '../utils/GetKindergartenByPathname';
import { linkUrl } from '../utils/LinkUrl';
import { findSocialUrl } from '../utils/SocialUrl';
import { theme } from '../utils/Theme';
import withLocation from '../utils/WithLocation';
import { Hamburger } from './mobile-menu/Hamburger';
import { MobileMenu } from './mobile-menu/MobileMenu';

const NavbarContainer = styled('div')`
  width: 100%;
  height: 135px;
  margin: 0 auto;
  padding: 24px 36px 24px 36px;
  display: flex;
  justify-content: space-around;

  ${theme.mediaQuerySizes.laptop} {
    padding-left: 20px;
    padding-right: 20px;
  }

  ${theme.mediaQuerySizes.smallLaptop} {
    height: 75px;
    padding: 10px;
    position: fixed;
    z-index: 100;
    background-color: ${theme.colors.white};
    top: 0;
  }
`;

const Links = styled('div')`
  margin-top: 34px;
  margin-left: auto;
  margin-right: 0px;

  ${theme.mediaQuerySizes.smallLaptop} {
    display: none;
  }
`;

interface NavbarLinkProps extends GatsbyLinkProps<any> {
  current?: boolean;
}

const NavbarLink = styled(Link)<NavbarLinkProps>`
  font-family: 'Verdana', 'Geneva', 'sans-serif';
  font-size: 18px;
  text-decoration: none;
  padding: 12px;
  transition: all 0.3s ease-out;
  z-index: 999;
  color: ${({ current }) => (current ? theme.colors.white : theme.colors.dark)};
  background-color: ${({ color, current }) =>
    current ? theme.colors[color ? color : theme.colors.darkGreen] : 'transparent'};

  :hover {
    color: ${theme.colors.white};
    background-color: ${({ color }) => (color ? theme.colors[color] : theme.colors.darkGreen)};
  }

  ${theme.mediaQuerySizes.laptop} {
    padding: 10px;
    font-size: 16px;
  }

  ${theme.mediaQuerySizes.smallLaptop} {
    padding: 10px;
    font-size: 20px;
    font-weight: 900;
    color: #fff;
    text-shadow: ${theme.colors.black} 1px 1px 5px;
    font-size: 3.5vh;

    :hover {
      color: ${theme.colors.lightGreen};
      margin-bottom: -1px;
    }
  }
`;

const NavbarLogo = styled(Logo)`
  width: 200px;
  vertical-align: middle;
  height: 100%;
  transition: filter 0.2s ease-out;
  :hover {
    filter: brightness(120%);
  }

  ${theme.mediaQuerySizes.smallLaptop} {
    width: 150px;
  }
`;

const SocialIcons = css`
  height: auto;
  width: 40px;
  opacity: 0.7;
  margin-left: 10px;
  :hover {
    opacity: 1;
  }
`;

const FacebookIcon = styled(FacebookSvgIcon)`
  ${SocialIcons}
`;

const InstagramIcon = styled(InstagramSvgIcon)`
  ${SocialIcons}
`;

const YoutubeIcon = styled(YoutubeSvgIcon)`
  ${SocialIcons}
`;

const SocialLinks = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 150px;
  ${theme.mediaQuerySizes.smallLaptop} {
    display: none;
  }
`;

const SocialLink = styled.a``;

interface QueryResponse {
  allSubpagesJson: {
    edges: SubpagesNode[];
  };
}

interface NavbarProps {
  location: LocationProps;
}

const query = graphql`
  query {
    allSubpagesJson {
      edges {
        node {
          url
          title
          color
        }
      }
    }
  }
`;

const Navbar = (params: NavbarProps) => {
  return (
    <StaticQuery
      query={query}
      render={(data: QueryResponse) => {
        const [isMenuOpen, setMenuState] = useState(false);

        const toggleMobileMenu = () => {
          setMenuState(!isMenuOpen);
        };

        return (
          <>
            <Hamburger onClick={toggleMobileMenu} isOpen={isMenuOpen} />
            <MobileMenu isOpen={isMenuOpen}>
              {data.allSubpagesJson.edges.map((value) => {
                return (
                  <NavbarLink
                    key={value.node.url}
                    onClick={toggleMobileMenu}
                    to={linkUrl(params.location.pathname, value.node.url)}
                  >
                    {value.node.title}
                  </NavbarLink>
                );
              })}
              <NavbarLink
                key="facebookMobileLink"
                onClick={toggleMobileMenu}
                to={findSocialUrl(decodeUri(getKindergartenByPathname(params.location.pathname)), 'facebook')}
              >
                FACEBOOK
              </NavbarLink>
              <NavbarLink
                key="instagramMobileLink"
                onClick={toggleMobileMenu}
                to={findSocialUrl(decodeUri(getKindergartenByPathname(params.location.pathname)), 'instagram')}
              >
                INSTAGRAM
              </NavbarLink>
              <NavbarLink
                key="youtubeMobileLink"
                onClick={toggleMobileMenu}
                to={findSocialUrl(decodeUri(getKindergartenByPathname(params.location.pathname)), 'youtube')}
              >
                YOUTUBE
              </NavbarLink>
            </MobileMenu>
            <NavbarContainer>
              <SocialLinks>
                <SocialLink
                  href={findSocialUrl(decodeUri(getKindergartenByPathname(params.location.pathname)), 'facebook')}
                  aria-label="Facebook"
                >
                  <FacebookIcon></FacebookIcon>
                </SocialLink>
                <SocialLink
                  href={findSocialUrl(decodeUri(getKindergartenByPathname(params.location.pathname)), 'instagram')}
                  aria-label="Instagram"
                >
                  <InstagramIcon></InstagramIcon>
                </SocialLink>
                <SocialLink
                  href={findSocialUrl(decodeUri(getKindergartenByPathname(params.location.pathname)), 'youtube')}
                  aria-label="Youtube"
                >
                  <YoutubeIcon></YoutubeIcon>
                </SocialLink>
              </SocialLinks>
              <Link to="/" aria-label="Strona główna">
                <NavbarLogo alt="Strona główna" />
              </Link>
              <Links>
                {data.allSubpagesJson.edges.map((value) => {
                  return (
                    <NavbarLink
                      key={value.node.url}
                      to={linkUrl(params.location.pathname, value.node.url)}
                      color={value.node.color}
                      current={params.location.pathname.includes(value.node.url)}
                    >
                      {value.node.title}
                    </NavbarLink>
                  );
                })}
              </Links>
            </NavbarContainer>
          </>
        );
      }}
    />
  );
};

export default withLocation(Navbar);
