export const colors: Record<string, string> = {
  logoGreen: '#4EB748',
  selectionGreen: '#CBFFC7',
  green: '#4AA544',
  red: '#EE3F64',
  pink: '#EF56B1',
  lightGreen: '#A0C047',
  darkGreen: '#337b2e',
  orange: '#C28165',
  violet: '#706994',
  yellow: '#F8BE11',
  blue: '#29A2D1',
  white: '#FFFFFF',
  whiteTransparent: '#ffffffcc',
  black: '#000000',
  light: '#EAEAEA',
  dark: '#333333',
  gray: '#888888',
  creamy: '#FDFBFA',
  fbBlue: '#4B68A0',
  brown: '#622a14',
};
