// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nowa-iwiczna-tsx": () => import("./../../../src/pages/nowa-iwiczna.tsx" /* webpackChunkName: "component---src-pages-nowa-iwiczna-tsx" */),
  "component---src-pages-piaseczno-tsx": () => import("./../../../src/pages/piaseczno.tsx" /* webpackChunkName: "component---src-pages-piaseczno-tsx" */),
  "component---src-pages-stara-iwiczna-tsx": () => import("./../../../src/pages/stara-iwiczna.tsx" /* webpackChunkName: "component---src-pages-stara-iwiczna-tsx" */),
  "component---src-pages-templates-about-tsx": () => import("./../../../src/pages/templates/about.tsx" /* webpackChunkName: "component---src-pages-templates-about-tsx" */),
  "component---src-pages-templates-activities-and-attractions-tsx": () => import("./../../../src/pages/templates/activities-and-attractions.tsx" /* webpackChunkName: "component---src-pages-templates-activities-and-attractions-tsx" */),
  "component---src-pages-templates-contact-tsx": () => import("./../../../src/pages/templates/contact.tsx" /* webpackChunkName: "component---src-pages-templates-contact-tsx" */),
  "component---src-pages-templates-enrollments-and-fees-tsx": () => import("./../../../src/pages/templates/enrollments-and-fees.tsx" /* webpackChunkName: "component---src-pages-templates-enrollments-and-fees-tsx" */),
  "component---src-pages-templates-gallery-tsx": () => import("./../../../src/pages/templates/gallery.tsx" /* webpackChunkName: "component---src-pages-templates-gallery-tsx" */),
  "component---src-pages-templates-home-tsx": () => import("./../../../src/pages/templates/home.tsx" /* webpackChunkName: "component---src-pages-templates-home-tsx" */),
  "component---src-pages-templates-parents-toolbox-tsx": () => import("./../../../src/pages/templates/parents-toolbox.tsx" /* webpackChunkName: "component---src-pages-templates-parents-toolbox-tsx" */)
}

