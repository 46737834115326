import React from 'react';

import { LayoutPropsWithLocation } from '../interfaces';
import { Layout } from './Layout';
import { MainPageLayout } from './MainPageLayout';

export default ({ children, pageContext, location }: LayoutPropsWithLocation) => {
  if (pageContext && pageContext.layout === 'index') {
    return <MainPageLayout>{children}</MainPageLayout>;
  } else {
    return <Layout location={location}>{children}</Layout>;
  }
};
